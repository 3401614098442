var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"d-flex flex-column align-center full-height full-width"},[_c('PageTitle',{attrs:{"icon":"mdi-folder-search","title":_vm.$tc('NavigationForStaff.Offer.applications'),"loading":_vm.loader},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('v-text-field',{staticClass:"text-center ml-12",attrs:{"label":_vm.$tc('Views.a-1'),"id":"form-url","readonly":"","dense":"","outlined":"","hide-details":"","color":_vm.getInputColor,"append-icon":"mdi-content-copy","value":_vm.formURL},on:{"click:append":function($event){_vm.copyToClipboard('form-url', _vm.$tc('Views.a-2'))}}})]},proxy:true}])}),_c('v-row',{class:_vm.applications.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0'
        : 'full-width flex-grow-1'},[_c('v-col',{staticClass:"d-flex justify-center"},[(_vm.loader)?_c('Loader',{attrs:{"message":_vm.$tc('Views.a-3')}}):(!_vm.loader)?_c('v-card',{staticClass:"custom-card",attrs:{"elevation":"8"}},[_c('v-row',{staticClass:"d-flex justify-space-between align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('bar-search',{attrs:{"search":_vm.search,"readonly":_vm.loadingDT,"searchCallback":_vm.searchApplicationsByFilters,"label":_vm.$tc('Views.a-4')},on:{"update:search":function($event){_vm.search=$event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{staticClass:"pr-3",attrs:{"items":_vm.statuses,"label":_vm.$tc('Views.a-5'),"hide-details":"","outlined":"","color":_vm.getInputColor,"item-color":_vm.getInputColor},on:{"change":_vm.searchByStatus},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item))+" ")]}},{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item))+" ")]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"customDT",attrs:{"headers":_vm.headers,"items":_vm.applications,"server-items-length":_vm.pagination.totalItems,"loading":_vm.loadingDT,"footer-props":{
            itemsPerPageText: _vm.$tc('Views.a-6'),
            pageText: ("{0}-{1} " + (_vm.$tc('Feedback.feedbacksByPage')) + " {2}"),
            'items-per-page-all-text': _vm.$tc('Feedback.allFeedbacks'),
          },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"info","indeterminate":"","absolute":""}})]},proxy:true},{key:"no-data",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(" "+_vm._s(_vm.$tc("Views.a-7"))+" ")])]},proxy:true},{key:"loading",fn:function(){return [_c('p',{staticClass:"ma-6"},[_vm._v(_vm._s(_vm.$tc("Views.a-8")))])]},proxy:true},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.status.name == 'SUBMITTED')?_c('v-chip',{attrs:{"small":"","label":"","color":"amber darken-4","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):_vm._e(),(item.status.name == 'ON REVIEW')?_c('v-chip',{attrs:{"small":"","label":"","color":"green","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):_vm._e(),(item.status.name == 'CONTACTED')?_c('v-chip',{attrs:{"small":"","label":"","color":"indigo","dark":""}},[_vm._v(_vm._s(_vm._f("status")(item.status.name)))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.submission_date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.submission_date))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('DotsMenu',{attrs:{"options":[
                {
                  title: _vm.$tc('Action.details'),
                  icon: 'mdi-eye',
                  event: 'details',
                },
                {
                  title: _vm.$tc('Action.update'),
                  icon: 'mdi-pencil',
                  event: 'update',
                  permissions: {
                    list: [_vm.$constants.PRIVILEGES.APPLICATION],
                    type: 'U',
                  },
                } ]},on:{"update":function($event){return _vm.openApplicationUpdate(item)},"details":function($event){return _vm.openApplicationDetail(item.id)}}})]}}],null,true)})],1):_vm._e()],1)],1),(!_vm.loader)?_c('v-row',{class:_vm.applications.length >= 0 && !_vm.loader
        ? 'full-width flex-grow-0 mb-6'
        : 'full-width flex-grow-1 mb-6'},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-card',{staticClass:"pa-3 custom-card",attrs:{"elevation":"8"}},[_c('h3',[_vm._v(_vm._s(_vm.$tc("Views.s-7-1"))+":")]),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"my-3"},[_c('v-chip',{attrs:{"small":"","label":"","color":"amber darken-4","dark":""}},[_vm._v(_vm._s(_vm._f("status")("SUBMITTED")))]),_vm._v(" : "+_vm._s(_vm.$tc("Views.a-9"))+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-chip',{attrs:{"small":"","label":"","color":"green","dark":""}},[_vm._v(_vm._s(_vm._f("status")("ON REVIEW")))]),_vm._v(" : "+_vm._s(_vm.$tc("Views.a-10"))+" ")],1),_c('div',{staticClass:"my-3"},[_c('v-chip',{attrs:{"small":"","label":"","color":"indigo","dark":""}},[_vm._v(_vm._s(_vm._f("status")("CONTACTED")))]),_vm._v(" : "+_vm._s(_vm.$tc("Views.a-11"))+" ")],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialogUpdate),callback:function ($$v) {_vm.dialogUpdate=$$v},expression:"dialogUpdate"}},[_c('v-card',[_c('v-form',{ref:"roleForm"},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$tc("Views.a-12")))])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_c('v-container',[_c('v-row',[(_vm.applicationID !== '')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{staticClass:"pr-3",attrs:{"items":_vm.statusesForChange,"label":_vm.$tc('Views.a-13'),"hide-details":"","outlined":"","color":_vm.getInputColor,"item-color":_vm.getInputColor},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item))+" ")]}},{key:"selection",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("status")(item))+" ")]}}],null,false,1770246044),model:{value:(_vm.newStatus),callback:function ($$v) {_vm.newStatus=$$v},expression:"newStatus"}})],1):_vm._e()],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"same-size-btn mr-2",attrs:{"color":"error"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$tc("Action.cancel"))+" ")]),_c('v-btn',{staticClass:"same-size-btn",attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.updateApplicationStatus}},[_vm._v(" "+_vm._s(_vm.$tc("Views.a-14"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }