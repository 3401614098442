




















































































































































































































































import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import { CustomDate } from "@/mixins/custom-date";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { StyleCheck } from "@/mixins/style-check";
import BarSearch from "@/components/Input/BarSearch.vue";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, BarSearch, DotsMenu },
})
export default class Applications extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  CustomDate,
  StyleCheck
) {
  dialogUpdate = false;
  loader = false;
  loading = false;
  loadingDT = false;
  search = "";
  status = "";
  newStatus = "";
  applicationID = "";
  options = {};
  statuses = ["N/A", "SUBMITTED", "ON REVIEW", "CONTACTED"];
  statusesForChange = ["SUBMITTED", "ON REVIEW", "CONTACTED"];

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private get formURL(): string {
    return process.env.VUE_APP_HOST + "/project-application";
  }

  private async updateApplicationStatus() {
    this.loading = true;
    await this.$store
      .dispatch("application/updateApplication", {
        id: this.applicationID,
        status: this.newStatus,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("Views.a-15"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.searchApplicationsByFilters();
        this.closeDialog();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.a-e1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
    this.loading = false;
  }

  private closeDialog() {
    this.newStatus = "";
    this.applicationID = "";
    this.dialogUpdate = false;
  }

  private openApplicationUpdate(application: any) {
    this.applicationID = application.id;
    this.newStatus = application.status.name;
    this.dialogUpdate = true;
  }

  private openApplicationDetail(id: string) {
    this.navigate("applications/detail/" + id);
  }

  private async searchApplicationsByFilters() {
    this.loadingDT = true;

    await this.getApplications(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search,
      this.status
    );
    this.loadingDT = false;
  }

  private async searchByStatus(value) {
    this.loadingDT = true;

    await this.getApplications(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search,
      value
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getApplications(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search,
      this.status
    );
    this.loadingDT = false;
  }

  private async getApplications(
    page: number,
    size: number,
    text = "",
    status = ""
  ): Promise<void> {
    await this.$store
      .dispatch("application/getApplications", { page, size, text, status })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.a-e2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getApplications(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("Views.a-16"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.a-17"),
        value: "submission_date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.a-18"),
        value: "amount",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.a-19"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("Views.a-20"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get applications(): any[] {
    let response = this.$store.getters["application/getApplications"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.applications) {
      return response.applications;
    } else {
      return [];
    }
  }
}
